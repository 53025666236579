<template>
    <div class="shop_main noMenu" style="background: #FFF">
        <div class="shop_order_main">

            <userMenu type="4" />

            <div class="shop_order_list" v-if="type === 1">
                <div class="shop_address_title">
                    <h1 class="title">说明：为保证平台用户交易安全，必须进行实名认证后方可进行交易</h1>
                </div>

                <div class="shop_form mt30">
                    <el-form ref="form" :model="form" :inline="true" :rules="rules" size="small">
                        <div class="realNameContent">
                            <div class="left">
                                <el-form-item label="姓名：" class="mb20" prop="name">
                                    <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
                                </el-form-item>

                                <el-form-item label="证件号：" class="mb20" prop="idNumber">
                                    <el-input v-model="form.idNumber" placeholder="请输入证件号"></el-input>
                                </el-form-item>

                                <el-form-item label="身份证正反面：" class="mb20" prop="fileAddress">
                                    <div class="flexColumn">
                                        <div class="uploadExample">
                                            <el-upload
                                                    class="avatar-uploader"
                                                    :action="uploadURL"
                                                    :before-upload="beforeUploadImage"
                                                    :on-success="idFileUpAddressUpload"
                                                    :show-file-list="false">
                                                <img v-if="form.idFileUpAddress" :src="imageURL + form.idFileUpAddress" class="avatar">
                                                <i v-if="!form.idFileUpAddress" class="el-icon-plus avatar-uploader-icon"></i>
                                                <span v-if="!form.idFileUpAddress" class="text">正面</span>
                                            </el-upload>
                                            <div class="example">
                                                <el-image
                                                        class="avatar" fit="contain"
                                                        :src="require('../../../../assets/img/example/example_img02.png')"
                                                        :preview-src-list="[require('../../../../assets/img/example/example_img02.png')]">
                                                </el-image>
                                                <div class="desc">示例图<img src="../../../../assets/img/imgShow.png"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flexColumn mt20">
                                        <div class="uploadExample">
                                            <el-upload
                                                    class="avatar-uploader"
                                                    :action="uploadURL"
                                                    :before-upload="beforeUploadImage"
                                                    :on-success="idFileDownAddressUpload"
                                                    :show-file-list="false">
                                                <img v-if="form.idFileDownAddress" :src="imageURL + form.idFileDownAddress" class="avatar">
                                                <i v-if="!form.idFileDownAddress" class="el-icon-plus avatar-uploader-icon"></i>
                                                <span v-if="!form.idFileDownAddress" class="text">背面</span>
                                            </el-upload>
                                            <div class="example">
                                                <el-image
                                                        class="avatar" fit="contain"
                                                        :src="require('../../../../assets/img/example/example_img03.png')"
                                                        :preview-src-list="[require('../../../../assets/img/example/example_img03.png')]">
                                                </el-image>
                                                <div class="desc">示例图<img src="../../../../assets/img/imgShow.png"></div>
                                            </div>
                                        </div>
                                        <div class="el-upload__tip">支持扩展名：.jpg、.jpeg、.png格式，图片大小不超过10M，正反面照片分别上传</div>
                                    </div>
                                </el-form-item>
                            </div>

<!--                            <div class="right">-->
<!--                                <el-form-item label="身份证有效期：" class="mb20" prop="phone">-->
<!--                                    <el-date-picker-->
<!--                                            v-model="form.phone"-->
<!--                                            type="date"-->
<!--                                            value-format="yyyy-MM-dd"-->
<!--                                            placeholder="选择身份证有效期">-->
<!--                                    </el-date-picker>-->
<!--                                </el-form-item>-->


<!--                            </div>-->
                        </div>

                        <el-form-item class="mt24 mr0" style=" width: 100%">
                            <el-button type="primary" :loading="submitLoading" @click="toSubmitForm('form')">提交</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>

            <div class="shop_order_list" v-if="type === 2">
                <div class="realNameSuccess">
                    <img src="../../../../assets/img/success.png" class="icon" />
                    <h1>提交成功</h1>
                    <span>请耐心等待平台完成审核，审核将在3个工作日内完成</span>
                </div>
            </div>

            <div class="shop_order_list" v-if="type === 3">
                <div class="realNameSuccessHead">
                    <img src="../../../../assets/img/success.png" class="icon" />
                    <div class="info">
                        <h1>恭喜！您的实名认证已通过！</h1>
                        <span>实名认证通过后，您可以申请在平台开设店铺、发起交易及发布供应、求购信息</span>
                    </div>
                </div>

                <div class="realNameSuccessInfo">
                    <div class="title">
                        <h1>认证信息</h1>
                    </div>

                    <div class="item">姓名：{{dataInfo.name}}</div>
                    <div class="item">证件号：{{dataInfo.idNumber}}</div>
<!--                    <div class="item">证件有效期：2022-08-01</div>-->
                    <div class="item">身份证正反面：
                        <el-image
                                v-if="dataInfo.idFileUpAddress"
                                class="avatar" fit="contain"
                                :src="imageURL + dataInfo.idFileUpAddress"
                                :preview-src-list="[imageURL + dataInfo.idFileUpAddress]">
                        </el-image>
                        <el-image
                                v-if="dataInfo.idFileDownAddress"
                                class="avatar" fit="contain"
                                :src="imageURL + dataInfo.idFileDownAddress"
                                :preview-src-list="[imageURL + dataInfo.idFileDownAddress]">
                        </el-image>
                    </div>

                    <div class="btnGroup">
                        <el-button type="primary" size="small" @click="$router.push('/shop/realName/edit')">更新</el-button>
                    </div>
                </div>
            </div>

            <div class="shop_order_list" v-if="type === 4">
                <div class="realNameSuccessHead">
                    <img src="../../../../assets/img/fail.png" class="icon" />
                    <div class="info">
                        <h1>抱歉！您的实名认证审核未通过！</h1>
                        <span>驳回原因：{{dataInfo.denyMessage}}</span>
                    </div>
                </div>

                <div class="realNameSuccessInfo">
                    <div class="title">
                        <h1>认证信息</h1>
                    </div>

                    <div class="item">姓名：{{dataInfo.name}}</div>
                    <div class="item">证件号：{{dataInfo.idNumber}}</div>
<!--                    <div class="item">证件有效期：2022-08-01</div>-->
                    <div class="item">身份证正反面：
                        <el-image
                                v-if="dataInfo.idFileUpAddress"
                                class="avatar" fit="contain"
                                :src="imageURL + dataInfo.idFileUpAddress"
                                :preview-src-list="[imageURL + dataInfo.idFileUpAddress]">
                        </el-image>
                        <el-image
                                v-if="dataInfo.idFileDownAddress"
                                class="avatar" fit="contain"
                                :src="imageURL + dataInfo.idFileDownAddress"
                                :preview-src-list="[imageURL + dataInfo.idFileDownAddress]">
                        </el-image>
                    </div>

                    <div class="btnGroup">
                        <el-button type="primary" size="small" @click="$router.push('/shop/realName/edit')">修改</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import {getRealName, postRealName} from '../../../../api/shop/realName';
  import {imageURL, uploadURL} from "../../../../utils/config";
  import {beforeUploadImage} from "../../../../utils/method";
  const userMenu = () => import('../../../../components/layout/shop/userMenu');
  export default {
    name: "realName",
    data () {
      let validateFileAddress = (rule, value, callback) => {
        if (this.form.idFileUpAddress === '' || this.form.idFileDownAddress === '') {
          callback(new Error('请上传身份证'));
        } else {
          callback();
        }
      };
      return {
        type: 1,
        form: {
          name: '',
          idNumber: '',
          idFileUpAddress: '',
          idFileDownAddress: ''
        },
        rules: {
          name: [
            { required: true, message: '请输入姓名', trigger: 'blur' }
          ],
          idNumber: [
            { required: true, message: '请输入证件号', trigger: 'blur' },
            { pattern: '^[1-9]\\d{7}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])\\d{3}$|^[1-9]\\d{5}[1-9]\\d{3}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])\\d{3}([0-9]|X)$', message: '请输入正确的证件号', trigger: 'blur'}
          ],
          fileAddress: [
            { validator: validateFileAddress, required: true, trigger: 'blur' }
          ]
        },
        isRequestSend: false,
        submitLoading: false,
        imageURL: imageURL,
        uploadURL: uploadURL,
        beforeUploadImage: beforeUploadImage,
        dataInfo: {
          denyMessage: '',
          name: '',
          idNumber: '',
          idFileUpAddress: '',
          idFileDownAddress: '',
          auditStatus: ''
        }
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
      this.getRealName()
    },
    methods: {
      getRealName () {
        getRealName().then(res => {
          if (res.code === 500113) {
            this.type = 1
          }
          if (res.code === 0) {
            if (res.data.auditStatus === 0 || res.data.auditStatus === 3) {
              this.type = 2
            }
            if (res.data.auditStatus === 1) {
              this.type = 3
            }
            if (res.data.auditStatus === 2) {
              this.type = 4
            }
            this.dataInfo = res.data
            if (res.data.name && res.data.name.length > 0) {
              let name = res.data.name.substr(0, 1);
              for (let i = 0; i < res.data.name.length - 1; i++) {
                name = name + '*'
              }
              this.dataInfo.name = name;
            }
            if (res.data.idNumber && res.data.idNumber.length > 7) {
              this.dataInfo.idNumber = res.data.idNumber.substr(0, 3) + '***********' + res.data.idNumber.substr(res.data.idNumber.length - 4, 4);
            }
          }
        })
      },
      idFileUpAddressUpload (res) {
        if (res.code === 0) {
          this.form.idFileUpAddress = res.data
        }
      },
      idFileDownAddressUpload (res) {
        if (res.code === 0) {
          this.form.idFileDownAddress = res.data
        }
      },
      toSubmitForm (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            postRealName({
              data: this.form
            }).then(res => {
              this.submitLoading = false;
              if (res.code === 0) {
                this.getRealName();
                this.$message({
                  message: '实名认证提交成功',
                  showClose: true,
                  type: 'success'
                })
              }
            }).catch(() => {
              this.submitLoading = false;
            })
          } else {
            return false;
          }
        });
      }
    },
    components: {
      userMenu
    }
  }
</script>
